import React, { useEffect, useState, useRef } from 'react'
import {Button,  Table, Modal, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Backend from "../../Api/Backend";
import ImagemDefault from '../../Images/sem-imagem.png';


//scss
import './Index.scss'


import {Input,Textarea} from "../../Components/Form";
import Message from "../../Components/Message/Message";

export default function Index(props) {

    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        id_podcast: props.match.params.id,
        titulo:'',
        episodio:'',
        id_episodio_podcast:'',
        texto: '',
        imagem:'',
        data:'',
        status:'',
    });

    useEffect(() => {
        getList();

    }, [props]);

    const onClickFile = () => { inputFile.current.click() };
    const inputFile = useRef(null);
    async function onChangeFile(e){
        try
        {
            const formData = new FormData();
            formData.append("arquivo", e.target.files[0], e.target.files[0].name);
            let response = await Backend.post('/episodios-podcasts/upload', formData);

            data['imagem'] = response.data.url;

            setData({...data});

        } catch(e){
            Message.error(e)
        }
    }

    async function getList(){
        try{

            let list = await Backend.get('/episodios-podcasts/podcast/'+props.match.params.id);
            if(list.data.status === "error"){
                throw list.data.message;
            }

            setList(list.data.data);
        }catch(e){
            Message.error(e)
        }
        
    }

    async function remove(id){
        if (window.confirm("Tem certeza que deseja excluir?")) {
            try{

                let list = await Backend.delete('/episodios-podcasts/'+id);

                if(list.data.status === "error"){
                    throw list.data.message;
                }

                getList();

                Message.success(list.data.message);
            }catch(e){
                Message.error(e)
            }
        }
    }

    async function save(){
        try{
            let returnSave = {};
            
            if(data.id_episodio_podcast){
                returnSave = await Backend.put('/episodios-podcasts/'+data.id_episodio_podcast, data);
            }else{
                returnSave = await Backend.post('/episodios-podcasts/', data);
            }

            if(returnSave.data.status === 'error'){
                throw returnSave.data.message;
            }

            setShowModal(false);
            getList();
            Message.success(returnSave.data.message);
        }catch(e){
            Message.error(e)
        }
    }

    async function newPodcast(){
        setShowModal(true)
        setData({
            id_podcast: props.match.params.id,
            titulo:'',
            episodio:'',
            id_episodio_podcast:'',
            texto: '',
            data:'',
            status:'',
        })
    }

    async function editPodcast(id){
        try{

            let list = await Backend.get('/episodios-podcasts/'+id);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setData(list.data.data[0]);

            setShowModal(true)
        }catch(e){
            Message.error(e)
        }
    }


    async function changeInput(e)
    {
        let dataEdit = data
        dataEdit[e.target.name] = e.target.value
        setData({...dataEdit})
    }


    return(
        <section  className="content content-page">
            <Row>
                <Col>
                    <h2 className={'mt-2'}>Episódios Podcasts</h2>
                </Col>
                <Col>
                    <Button onClick={() =>  newPodcast()} className={'float-right'}><i className="fas fa-plus"></i> Adicionar</Button>
                    <Link to={'/podcasts/index'}>
                        <Button className={'float-right mr-3'} variant={'danger'}><i className="fas fa-arrow-left"></i> Voltar</Button>
                    </Link>
                    
                </Col>
            </Row>
            <div className={'card mt-3'}>

                <Table striped bordered hover >
                    <thead>
                        <tr>
                            
                            <th>Título</th>
                            <th>Texto</th>
                            <th>Episódio</th>
                            <th>Data</th>
                            <th>Visualizações</th>
                            <th><i className="fas fa-edit"></i> Editar</th>
                            <th><i className="fas fa-trash-alt"></i> Excluir</th>
                        </tr>
                    </thead>

                    <tbody>
                    { list.map((item ,key)=> (
                        <tr key={item.id_episodio_podcast}>
                            <td>{item.titulo}</td>
                            <td>{item.texto}</td>
                            <td>{item.episodio}</td>
                            <td>{item.data}</td>
                            <td>{item.visualizacoes}</td>
                            <td>
                                <Button variant={'success'} onClick={() => editPodcast(item.id_episodio_podcast)}><i className="fas fa-edit"></i> Editar</Button>
                            </td>
                            <td>
                                <Button variant={'danger'} onClick={() => remove(item.id_episodio_podcast)}><i className="fas fa-trash-alt"></i> Excluir</Button>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Criar/Editar Podcast</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        className={'form-control'}
                        name={'titulo'}
                        value={data.titulo}
                        label={'Título'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Textarea
                        className={'form-control'}
                        name={'texto'}
                        value={data.texto}
                        label={'Texto'}
                        onChange={(e) => changeInput(e)}
                    />

                    <Textarea
                        className={'form-control'}
                        name={'episodio'}
                        value={data.episodio}
                        label={'Link Episódio'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Input
                        className={'form-control'}
                        name={'data'}
                        type={'date'}
                        label={'data'}
                        value={data.data}
                        onChange={(e) => changeInput(e)}
                    />

                    <Row className={'mt-3'}>
                        <Col >
                            <input type="file" className="display-none" ref={inputFile} name="imagem"  onChange={(e) => onChangeFile(e)}  />
                            <Button className={'mt-6'} onClick={onClickFile} >
                                <i className={'fas fa-image'}></i> Selecionar Arquivo
                            </Button>
                        </Col>
                        <Col>
                            <div className={'w-100 border'} >
                                <img src={data.imagem ? data.imagem : ImagemDefault } className={'w-100 '} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        <i className={'fa fa-times'}></i> Fechar
                    </Button>
                    <Button variant="success" onClick={() => save()}>
                        <i className={'fa fa-save'}></i> Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )

}


















