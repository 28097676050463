import React from 'react';

export function Textarea(props){

    function onChange(e){
        props.onChange(e.target.name, e.target.value);
    }

    return (
        <>
            {(props.label) && <label>{props.label}</label>}

            <textarea
                onChange={props.onChange}
                name={props.name} 
                defaultValue={props.defaultValue}
                value={props.value}
                className={'form-control'}
                placeholder={props.placeholder} 
                autoFocus={props.autoFocus ? true : false}
                readOnly={props.readOnly ? true : false}
                maxLength={props.maxLength ? props.maxLength : 10000000000}
            >
                {props.value}
            </textarea>
        </>
    );

};