import React, { useEffect, useState, useRef } from 'react'
import {Button,  Table, Row, Col, Modal} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Pagination from '../../Components/Pagination/Pagination';

import Backend from "../../Api/Backend";

//scss
import './Index.scss'

import ImagemDefault from '../../Images/sem-imagem.png';

import {Input,Select,Textarea, TextareaEditor} from "../../Components/Form";
import Message from "../../Components/Message/Message";

export default function Index(props) {

    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        id_playlist: null,
        nome:'',
        imagem_thumb: '',
        imagem_interna:'',
        resumo:'',
        youtube:'',
        youtube_url:'',
        status:'',
        slug:'',
    });

    useEffect(() => {
        getList();

    }, [props]);

    const onClickFile = () => { inputFile.current.click() };
    const inputFile = useRef(null);
    async function onChangeFile(e){
        try
        {
            const formData = new FormData();
            formData.append("imagem", e.target.files[0], e.target.files[0].name);
            let response = await Backend.post('/playlists/upload', formData);



            data['imagem_thumb'] = 'https://backendnovo.alphafm.com.br'+response.data.location;

            setData({...data});

        } catch(e){
            Message.error(e)
        }
    }

    const onClickFile2 = () => { inputFile2.current.click() };
    const inputFile2 = useRef(null);
    async function onChangeFile2(e){
        try
        {
            const formData = new FormData();
            formData.append("imagem", e.target.files[0], e.target.files[0].name);
            let response = await Backend.post('/playlists/upload', formData);

            data['imagem_interna'] = response.data.url;

            setData({...data});

        } catch(e){
            Message.error(e)
        }
    }

    async function getList(){
        try{
            let params = new URLSearchParams(props.location.search);
            params.append('paginacao',true);
            params.append('itens',10);

            let list = await Backend.get('/playlists?'+params);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setList(list.data.data.data);
            setPagination(list.data.data);
        }catch(e){
            Message.error(e)
        }
        
    }

    async function remove(id){
        if (window.confirm("Tem certeza que deseja excluir?")) {
            try{

                let list = await Backend.delete('/playlists/'+id);

                if(list.data.status === "error"){
                    throw list.data.message;
                }

                getList();

                Message.success(list.data.message);
            }catch(e){
                Message.error(e)
            }
        }
    }

    async function save(){
        try{
            let returnSave = {};
            
            if(data.id_playlist){
                returnSave = await Backend.put('/playlists/'+data.id_playlist, data);
            }else{
                returnSave = await Backend.post('/playlists/', data);
            }

            if(returnSave.data.status === 'error'){
                throw returnSave.data.message;
            }

            setShowModal(false);
            getList();
            Message.success(returnSave.data.message);
        }catch(e){
            Message.error(e)
        }
    }

    async function newPlaylist(){
        setShowModal(true)
        setData({
            id_playlist: null,
            nome:'',
            imagem_thumb: '',
            imagem_interna:'',
            resumo:'',
            youtube:'',
            youtube_url:'',
            status:'',
            slug:'',
        })
    }

    async function editPlaylist(id){
        try{

            let list = await Backend.get('/playlists/'+id);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setData(list.data.data[0]);

            setShowModal(true)
        }catch(e){
            Message.error(e)
        }
    }

    async function changeInput(e)
    {
        let dataEdit = data
        dataEdit[e.target.name] = e.target.value
        setData({...dataEdit})
    }


    return(
        <section  className="content content-page">
            <Row>
                <Col>
                    <h2 className={'mt-2'}>Playlists</h2>
                </Col>
                <Col>
                    <Button onClick={() =>  newPlaylist()} className={'float-right'}><i className="fas fa-plus"></i> Adicionar</Button>
                </Col>
            </Row>
            <div className={'card mt-3'}>

                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Imagem Thumb</th>
                            <th>Imagem Interna</th>
                            <th>Nome</th>
                            <th>Resumo</th>
                            <th><i className="fas fa-plus"></i> Músicas</th>
                            <th><i className="fas fa-edit"></i> Editar</th>
                            <th><i className="fas fa-trash-alt"></i> Excluir</th>
                        </tr>
                    </thead>

                    <tbody>
                    { list.map((item ,key)=> (
                        <tr key={item.id_playlist}>
                            <td>
                                <img width="100" src={item.imagem_thumb.includes('backendnovo') ? item.imagem_thumb : 'https://alphafm.com.br/assets/uploads/playlists/'+item.imagem_thumb} alt={item.id_playlist} />
                            </td>
                            <td>
                                <img width="100" src={item.imagem_interna.includes('backendnovo') ? item.imagem_interna : 'https://alphafm.com.br/assets/uploads/playlists/'+item.imagem_interna} alt={item.id_playlist} />
                            </td>
                            <td>{item.nome}</td>
                            <td  dangerouslySetInnerHTML={{ __html: item.resumo}}></td>
                            <td>
                                <Link to={'/playlists/musicas/'+item.id_playlist}>
                                    <Button variant={'primary'}><i className="fas fa-plus"></i> Músicas</Button>
                                </Link>
                            </td>
                            <td>
                                <Button variant={'success'} onClick={() => editPlaylist(item.id_playlist)}><i className="fas fa-edit"></i> Editar</Button>
                            </td>
                            <td>
                                <Button variant={'danger'} onClick={() => remove(item.id_playlist)}><i className="fas fa-trash-alt"></i> Excluir</Button>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            </div>
            <Pagination  pagination={pagination} />

            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Criar/Editar Playlist</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        className={'form-control'}
                        name={'nome'}
                        value={data.nome}
                        label={'Nome'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Textarea
                        className={'form-control'}
                        name={'resumo'}
                        value={data.resumo}
                        label={'Resumo'}
                        onChange={(e) => changeInput(e)}
                    />

                    <Row className={'mt-3'}>
                        <Col >
                            <label classname={'form-control'}>Imagem Thumb</label>
                            <input type="file" ref={inputFile} name="imagem_thumb" className={'display-none'} onChange={(e) => onChangeFile(e)}  />
                            <Button className={'mt-6'} onClick={onClickFile} >
                                <i className={'fas fa-image'}></i> Selecionar imagem
                            </Button>
                        </Col>
                        <Col>
                            <div className={'w-100 border'} >
                                <img src={data.imagem_thumb ? data.imagem_thumb : ImagemDefault } className={'w-100 '} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col >
                            <label classname={'form-control'}>Imagem Interna</label>
                            <input type="file" ref={inputFile2} name="imagem_interna" className={'display-none'} onChange={(e) => onChangeFile2(e)}  />
                            <Button className={'mt-6'} onClick={onClickFile2} >
                                <i className={'fas fa-image'}></i> Selecionar imagem
                            </Button>
                        </Col>
                        <Col>
                            <div className={'w-100 border'} >
                                <img src={data.imagem_interna ? data.imagem_interna : ImagemDefault } className={'w-100 '} alt="" />
                            </div>
                        </Col>
                    </Row>
                    <Row className={'mt-3'}>
                        <Col >
                            <label classname={'form-control'}>Link Playlist</label>
                            <textarea name="youtube_url" className={'form-control'} value={data.youtube_url} onChange={(e) => changeInput(e)} label={'Link Playlist'}></textarea>

                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        <i className={'fa fa-times'}></i> Fechar
                    </Button>
                    <Button variant="success" onClick={() => save()}>
                        <i className={'fa fa-save'}></i> Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )

}