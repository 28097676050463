import React from 'react';
import InputMask  from 'react-input-mask';
import NumberFormat from 'react-number-format';

export function Input(props){
    
    function onChange(name, value){
        // console.log(name, value)
        props.onChange(name, value);
    }

    return (
        <>
            {(props.label) && <label>{props.label}</label>}

            {( props.mask === "currency" ) &&
            <NumberFormat 
                className={props.className} 
                onValueChange={(value) => onChange(props.name, value.value)} 
                defaultValue={props.defaultValue} 
                value={props.value} 
                placeholder={props.placeholder} 
                autoFocus={props.autoFocus ? true : false}
                readOnly={props.readOnly ? true : false}
                maxLength={props.maxLength && props.maxLength}
                name={props.name}
                prefix="R$ "
                isNumericString={true}
                fixedDecimalScale={true}
                decimalScale="2"
                decimalSeparator=","
                thousandSeparator="."
            />
            }
        
            {( props.mask === "integer" ) &&
            <input type="number"
                className={props.className} 
                onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)} 
                onKeyUp={(e) => props.onKeyUp && props.onKeyUp(e)}
                onChange={props.onChange}
                   name={props.name}
                   pattern="\d*"
                defaultValue={props.defaultValue} 
                value={props.value} 
                placeholder={props.placeholder} 
                autoFocus={props.autoFocus ? true : false}
                readOnly={props.readOnly ? true : false}
                maxLength={props.maxLength && props.maxLength}
            />
            }

            {( props.mask && !["currency","integer"].includes(props.mask) ) &&
            <InputMask type='text' mask={props.mask} maskChar={props.maskChar}
                className={props.className} 
                onChange={props.onChange}
                name={props.name} 
                defaultValue={props.defaultValue}
                value={props.value}
                placeholder={props.placeholder} 
                autoFocus={props.autoFocus ? true : false}
                readOnly={props.readOnly ? true : false}
                maxLength={props.maxLength && props.maxLength}
            />
            }

            {( !props.mask ) &&
            <input type={props.type ? props.type : 'text'}
                className={props.className} 
                onKeyDown={(e) => props.onKeyDown && props.onKeyDown(e)} 
                onKeyUp={(e) => props.onKeyUp && props.onKeyUp(e)}
                   onChange={props.onChange}
                   name={props.name}
                defaultValue={props.defaultValue}
                 disabled={props.disabled ? 'disabled':''}

                value={props.value}
                placeholder={props.placeholder} 
                autoFocus={props.autoFocus ? true : false}
                readOnly={props.readOnly ? true : false}
                maxLength={props.maxLength && props.maxLength}
            />
            }
        </>
    );

};