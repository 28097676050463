import React, { useEffect, useState } from 'react'
import {Button, Card, Col, Row} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Backend from '../../Api/Backend';

//scss
import './Index.scss'

export default function Index(props) {
    const [youtubeTotal, setYoutubeTotal] = useState(0);
    const [podcastsTotal, setPodcastsTotal] = useState(0);
    const [playlistsTotal, setPlaylistsTotal] = useState(0);

    useEffect(() => {
        getList();

    }, [props]);

    async function getList(){
        let youtube = await Backend.get('/videos-youtube');
        
        if(youtube.data.data){
            setYoutubeTotal(youtube.data.data.length);
        }

        let podcasts = await Backend.get('/podcasts');
        
        if(podcasts.data.data){
            setPodcastsTotal(podcasts.data.data.length);
        }

        let playlists = await Backend.get('/playlists');
        
        if(playlists.data.data){
            setPlaylistsTotal(playlists.data.data.length);
        }
        
    }

    return(
        <section  className="content content-page">
            <h2>Home</h2>
            <Row>
                <Col>
                    <Card>
                        <Card.Header as="h5">Playlists</Card.Header>
                        <Card.Body>
                            <Card.Title>Playlists e músicas apresentadas no site.</Card.Title>
                            <Card.Text>
                            Total: <b>{playlistsTotal}</b>
                            </Card.Text>
                            <Link to={'/playlists/index'}>
                                <Button variant="primary">Adicionar / Editar</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card>
                        <Card.Header as="h5">Podcasts</Card.Header>
                        <Card.Body>
                            <Card.Title>Podcasts e episodios apresentadas no site.</Card.Title>
                            <Card.Text>
                                Total: <b>{podcastsTotal}</b>
                            </Card.Text>
                            <Link to={'/podcasts/index'}>
                                <Button variant="primary">Adicionar / Editar</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
                <Col>
                    <Card >
                        <Card.Header as="h5">Youtube</Card.Header>
                        <Card.Body>
                            <Card.Title>Videos do Youtube da AlphaFM</Card.Title>
                            <Card.Text>
                            Total: <b>{youtubeTotal}</b>
                            </Card.Text>
                            <Link to={'/youtube/index'}>
                                <Button variant="primary">Adicionar / Editar</Button>
                            </Link>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </section>
    )

}