import React, { useEffect, useState, useRef } from 'react'
import {Button,  Table, Modal, Row, Col} from 'react-bootstrap';
import {Link} from "react-router-dom";
import Pagination from '../../Components/Pagination/Pagination';
import Backend from "../../Api/Backend";

//scss
import './Index.scss'

import ImagemDefault from '../../Images/sem-imagem.png';

import {Input,Textarea} from "../../Components/Form";
import Message from "../../Components/Message/Message";

export default function Index(props) {

    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        id_podcast: null,
        titulo:'',
        texto: '',
        ordem:1,
        imagem:''
    });

    useEffect(() => {
        getList();

    }, [props]);

    const onClickFile = () => { inputFile.current.click() };
    const inputFile = useRef(null);
    async function onChangeFile(e){
        try
        {
            const formData = new FormData();
            formData.append("imagem", e.target.files[0], e.target.files[0].name);
            let response = await Backend.post('/podcasts/upload', formData);

            data['imagem'] = response.data.url;

            setData({...data});

        } catch(e){
            Message.error(e)
        }
    }

    async function getList(){
        try{
            let params = new URLSearchParams(props.location.search);
            params.append('paginacao',true);
            params.append('itens',10);

            let list = await Backend.get('/podcasts?'+params);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setList(list.data.data.data);
            setPagination(list.data.data);
        }catch(e){
            Message.error(e)
        }
        
    }

    async function remove(id){
        if (window.confirm("Tem certeza que deseja excluir?")) {
            try{

                let list = await Backend.delete('/podcasts/'+id);

                if(list.data.status === "error"){
                    throw list.data.message;
                }

                getList();

                Message.success(list.data.message);
            }catch(e){
                Message.error(e)
            }
        }
    }

    async function save(){
        try{
            let returnSave = {};
            
            if(data.id_podcast){
                returnSave = await Backend.put('/podcasts/'+data.id_podcast, data);
            }else{
                returnSave = await Backend.post('/podcasts/', data);
            }

            if(returnSave.data.status === 'error'){
                throw returnSave.data.message;
            }

            setShowModal(false);
            getList();
            Message.success(returnSave.data.message);
        }catch(e){
            Message.error(e)
        }
    }

    async function newPodcast(){
        setShowModal(true)
        setData({
            id_podcast: null,
            titulo:'',
            texto: '',
            ordem:1,
            imagem:''
        })
    }

    async function editPodcast(id){
        try{

            let list = await Backend.get('/podcasts/'+id);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setData(list.data.data[0]);

            setShowModal(true)
        }catch(e){
            Message.error(e)
        }
    }


    async function changeInput(e)
    {
        let dataEdit = data
        dataEdit[e.target.name] = e.target.value
        setData({...dataEdit})
    }


    return(
        <section  className="content content-page">
            <Row>
                <Col>
                    <h2 className={'mt-2'}>Podcasts</h2>
                </Col>
                <Col>
                    <Button onClick={() =>  newPodcast()} className={'float-right'}><i className="fas fa-plus"></i> Adicionar</Button>
                </Col>
            </Row>
            <div className={'card mt-3'}>

                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Imagem</th>
                            <th>Título</th>
                            <th>Descrição</th>
                            <th>Visualizações</th>
                            <th>Ordem</th>
                            <th><i className="fas fa-plus"></i> Episódios</th>
                            <th><i className="fas fa-edit"></i> Editar</th>
                            <th><i className="fas fa-trash-alt"></i> Excluir</th>
                        </tr>
                    </thead>

                    <tbody>
                    { list.map((item ,key)=> (
                        <tr key={item.id_podcast}>
                            <td>
                                <img width="100" src={item.imagem} alt={item.id_playlist} />
                            </td>
                            <td>{item.titulo}</td>
                            <td>{item.texto}</td>
                            <td>{item.ordem}</td>
                            <td>{item.visualizacoes}</td>
                            <td>
                                <Link to={'/podcasts/episodios/'+item.id_podcast}>
                                    <Button variant={'primary'}><i className="fas fa-plus"></i> Episódios</Button>
                                </Link>
                            </td>
                            <td>
                                <Button variant={'success'} onClick={() => editPodcast(item.id_podcast)}><i className="fas fa-edit"></i> Editar</Button>
                            </td>
                            <td>
                                <Button variant={'danger'} onClick={() => remove(item.id_podcast)}><i className="fas fa-trash-alt"></i> Excluir</Button>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            </div>
            <Pagination  pagination={pagination} />

            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Criar/Editar Podcast</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        className={'form-control'}
                        name={'titulo'}
                        value={data.titulo}
                        label={'Título'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Textarea
                        className={'form-control'}
                        name={'texto'}
                        value={data.texto}
                        label={'Texto'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Input
                        className={'form-control'}
                        name={'ordem'}
                        mask={'integer'}
                        label={'Ordem'}
                        value={data.ordem}
                        onChange={(e) => changeInput(e)}
                    />
                    <Row className={'mt-3'}>
                        <Col >
                            <input type="file" ref={inputFile} name="imagem" className={'display-none'} onChange={(e) => onChangeFile(e)}  />
                            <Button className={'mt-6'} onClick={onClickFile} >
                                <i className={'fas fa-image'}></i> Selecionar imagem
                            </Button>
                        </Col>
                        <Col>
                            <div className={'w-100 border'} >
                                <img src={data.imagem ? data.imagem : ImagemDefault } className={'w-100 '} alt="" />
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        <i className={'fa fa-times'}></i> Fechar
                    </Button>
                    <Button variant="success" onClick={() => save()}>
                        <i className={'fa fa-save'}></i> Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )

}