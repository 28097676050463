import axios from "axios";

var baseURL = "";
if( process.env.REACT_APP_STAGE === 'production' ){
    baseURL = 'https://backendnovo.alphafm.com.br/'
} else {
    baseURL = 'https://backendnovo.alphafm.com.br/'
}

const Backend = axios.create({baseURL: baseURL});

if( localStorage.getItem('token') ){
    Backend.defaults.headers.common['Authorization'] = "Bearer " + localStorage.getItem('token');
}

export default Backend;