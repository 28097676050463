import React, { useEffect, useState } from 'react'
import Message from '../../Components/Message/Message'

//scss
import './Login.scss'

//images
import Logo from '../../Images/logo.gif'
import { Input } from '../../Components/Form'
import Backend from '../../Api/Backend'

export default function Login(props) {
    
    const [DTO, setDTO] = useState({
        login: "",
        senha: ""
    });

    useEffect(() => {

    }, [props]);

    async function handleLogin(e){
        e.preventDefault();

        try
        {
            let response = await Backend.post('/admin/login', DTO);

            if( response.data.status === 'error' ) throw response.data.message;
         
            window.localStorage.setItem('token', response.data.data);
            window.location = "/clientes/index";

        } catch(error){
            Message.error(error);
        }
    }

    function onChangeDTO(e){

        DTO[e.target.name] = e.target.value;
        setDTO({...DTO});
        return true;
    }

    return(
        <>

        <div id="login">

            <form onSubmit={handleLogin}>

                <div className="logo">
                    <img alt="Logo m.eat" src={Logo} />
                </div>

                <label>
                    <Input placeholder="Login" autoFocus={true} name="login" value={DTO.login} onChange={(e) => onChangeDTO(e)} />
                </label>

                <label>
                    <Input type="password" placeholder="Senha" name="senha" value={DTO.senha} onChange={(e) => onChangeDTO(e)} />
                </label>

                <button type="submit" className="btn btn-pink w-100">Entrar</button>
                <br/>
            </form>
        </div>
        </>
    )

}