import React, { useEffect, useState } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'

//scss
import './LayoutPrivate.scss'

//images
import Logo from '../../Images/logo.gif'

export default function LayoutPrivate(props){

    const history = useHistory()
    const location = useLocation()
    const [title, setTitle] = useState("")
    const [sidebar, setSidebar] = useState(false)
    const [search, setSearch] = useState("")
    
    const [profile, setProfile] = useState({
        nome: "Admin",
        fotos: {perfil: "Teste"}
    })

    useEffect(() => {
        
        //let storageProfile = JSON.parse(window.localStorage.getItem('profile'));
        //setProfile(storageProfile);

    }, [props])

    useEffect(() => {

        async function getTitle(){
            let title = "Home";
            if( location.pathname.includes("/produtos") ) title = "Produtos cadastrados";


            setTitle(title)
        }
        getTitle();

        let search = new URLSearchParams(props.location.search).get("search");
        if( search ){
            setSearch(search);
        }

    }, [location.pathname])

    async function handleLogout()
    {
        window.localStorage.setItem('token', 'false')
        window.location.href = '/login';
    }

    async function handleSearch(e)
    {
        e.preventDefault();
        history.push(`/produtos?search=${search}`)
    }

    return(
        <div id="meat">

            <div className={'sidebar active'} onClick={(e) => (e.target.className === "sidebar active" ? setSidebar(false) : null)}>
                <nav>
                    <button className="btn-sidebar-close" onClick={() => setSidebar(false)}>X</button>

                    <div className="user">
                        <div className={'logo'}>
                            <img src={Logo} alt={'logo'} width={120}/>
                        </div>
                    </div>
                    <p>Menu</p>
                    <ul>
                        <li><Link to="/home/index" onClick={() => setSidebar(false)} className={'dois'}><i className="fas fa-home"></i>Home</Link></li>
                        <li><Link to="/playlists/index" onClick={() => setSidebar(false)} className={'dois'}><i className="fas fa-play-circle"></i>Playlists</Link></li>
                        <li><Link to="/podcasts/index" onClick={() => setSidebar(false)} className={'dois'}><i className="fas fa-microphone"></i>Podcasts</Link></li>
                        <li><Link to="/youtube/index" onClick={() => setSidebar(false)} className={'dois'}><i className="fab fa-youtube"></i>Youtube</Link></li>
                    </ul>
                </nav>
            </div>

            <header className={`${sidebar ? 'blur' : null}`}>

                <div className="content">

                    <div>

                        <h1 className="title">{title}</h1>

                    </div>

                    <div>

                        {/* {location.pathname === "/frete" &&
                        <button className="btn btn-featured">+ Adicionar meu Frete</button>
                        } */}

                        <div className="user">
                            <span className="welcome float-left">
                                Ola, {profile.nome}
                            </span>
                            <span className="image" style={{backgroundImage: profile.fotos.perfil ? "url(" + profile.fotos.perfil + ")" : ""}}></span>
                            <span className="welcome cursor-pointer" style={{cursor:'pointer'}} onClick={handleLogout}>
                                <i className="fas fa-sign-out-alt"></i> Logout
                            </span>
                        </div>
                    </div>

                </div>

            </header>


                {props.children}

        </div>
    )

}