import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function Pagination(props){

    const [pages, setPages] = useState([]);
    const [prev, setPrev] = useState();
    const [next, setNext] = useState();
    const location = useLocation();
    useEffect(() => {

        function initPages()
        {

            console.log(props.pagination ) ;           
            let array = [];
            let params = new URLSearchParams(location.search);


            for( var i=1; i<=props.pagination.last_page; i++ )
            {
                params.set('pagina', i);
                let to = location.pathname + "?" + params.toString();
                let active = (parseInt(props.pagination.current_page) === i) ? "btn-primary" : "btn-secondary";
                array.push(<li className={'paginacao mb-2'} key={i}><Link to={to} className={`btn ${active}`}>{i}</Link></li>);
            }

            setPages(array);
        }
    
        function initNext()
        {
            let params = new URLSearchParams(location.search);

            let i = (props.pagination.total === props.pagination.current_page) ? props.pagination.total : (parseInt(props.pagination.current_page) + 1);
            params.set('pagina', i);
            let to = location.pathname + "?" + params.toString();
            setNext(<Link to={to} className="btn btn-secondary" style={{height:'43px'}}>Próxima</Link>);
        }
    
        function initPrev()
        {
            let params = new URLSearchParams(location.search);
            let i = (props.pagination.current_page <= 1) ? 1 : (props.pagination.current_page - 1);
            params.set('pagina', i);
            let to = location.pathname + "?" + params.toString();
            setPrev(<Link to={to} className="btn btn-secondary" style={{height:'43px'}}>Anterior</Link>);
        }

        initPages();
        initNext();
        initPrev();

    }, [props, location]);

    return (
        <div className="pagination mt-3 mb-3">
            {prev}
            <ul className={'paginacao'}>{pages}</ul>
            {next}
        </div>
    );

};