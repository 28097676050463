import React, { useEffect, useState, useRef } from 'react'
import {Button,  Table, Row, Col, Modal, Breadcrumb} from 'react-bootstrap';
import {Link} from "react-router-dom";

import Backend from "../../Api/Backend";

//scss
import './Index.scss'

import ImagemDefault from '../../Images/sem-imagem.png';

import {Input,Textarea} from "../../Components/Form";
import Message from "../../Components/Message/Message";

export default function Musicas(props) {

    const [list, setList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        id_playlist_musica: null,
        playlist:props.match.params.id,
        artista: '',
        musica:1,
        imagem_thumb:'',
        mp3_url:'',
    });

    useEffect(() => {
        getList(props.match.params.id);

    }, [props]);

    const onClickFile = () => { inputFile.current.click() };
    const inputFile = useRef(null);
    async function onChangeFile(e){
        try
        {
            const formData = new FormData();
            formData.append("arquivo", e.target.files[0], e.target.files[0].name);
            let response = await Backend.post('/playlists/upload', formData);

            data['imagem'] = response.data.url;

            setData({...data});

        } catch(e){
            Message.error(e)
        }
    }

    async function getList(id_playlist){
        try{
            let list = await Backend.get('/musicas-playlists/playlist/'+id_playlist);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setList(list.data.data);
        }catch(e){
            Message.error(e)
        }
        
    }

    async function remove(id){
        if (window.confirm("Tem certeza que deseja excluir?")) {
            try{

                let list = await Backend.delete('/musicas-playlists/'+id);

                if(list.data.status === "error"){
                    throw list.data.message;
                }

                getList();

                Message.success(list.data.message);
            }catch(e){
                Message.error(e)
            }
        }
    }

    async function save(){
        try{
            let returnSave = {};
            
            if(data.id_playlist_musica){
                console.log(data);
                returnSave = await Backend.put('/musicas-playlists/'+data.id_playlist_musica, data);
            }else{
                returnSave = await Backend.post('/musicas-playlists/', data);
            }

            if(returnSave.data.status === 'error'){
                throw returnSave.data.message;
            }

            setShowModal(false);
            getList(props.match.params.id);
            Message.success(returnSave.data.message);
        }catch(e){
            Message.error(e)
        }
    }

    async function newMusicaPlaylist(){
        setShowModal(true)
        setData({
            id_playlist_musica: null,
            playlist:props.match.params.id,
            artista: '',
            musica:1,
            imagem_thumb:'',
            mp3_url:'',
        })
    }

    async function editMusicaPlaylist(id){
        try{

            let list = await Backend.get('/musicas-playlists/'+id);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setData(list.data.data[0]);

            setShowModal(true)
        }catch(e){
            Message.error(e)
        }
    }

    async function changeInput(e)
    {
        let dataEdit = data
        dataEdit[e.target.name] = e.target.value
        setData({...dataEdit})
    }


    return(
        <section  className="content content-page">
            <Row>
                <Col>
                    <h2 className={'mt-2'}>Músicas Playlists</h2>
                </Col>
                <Col>
                    <Button onClick={() =>  newMusicaPlaylist()} className={'float-right'}><i className="fas fa-plus"></i> Adicionar</Button>
                    <Link to={'/playlists/index'}>
                        <Button className={'float-right mr-3'} variant={'danger'}><i className="fas fa-arrow-left"></i> Voltar</Button>
                    </Link>
                    
                </Col>
            </Row>
            <div className={'card mt-3'}>

                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>Imagem thumb</th>
                            <th>Artista</th>
                            <th>Musica</th>
                            <th>URL Mp3</th>
                            <th><i className="fas fa-edit"></i> Editar</th>
                            <th><i className="fas fa-trash-alt"></i> Excluir</th>
                        </tr>
                    </thead>

                    <tbody>
                    { list.map((item ,key)=> (
                        <tr key={item.id_playlist_musica}>
                            <td><img width="100" src={item.imagem_thumb} alt={item.id_playlist_musica} /></td>
                            <td>{item.artista}</td>
                            <td>{item.musica}</td>
                            <td>{item.mp3_url}</td>
                            <td>
                                <Button variant={'success'} onClick={() => editMusicaPlaylist(item.id_playlist_musica)}><i className="fas fa-edit"></i> Editar</Button>
                            </td>
                            <td>
                                <Button variant={'danger'} onClick={() => remove(item.id_playlist_musica)}><i className="fas fa-trash-alt"></i> Excluir</Button>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            </div>

            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Criar/Editar Música</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        className={'form-control'}
                        name={'artista'}
                        value={data.artista}
                        label={'Artista'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Textarea
                        className={'form-control'}
                        name={'musica'}
                        value={data.musica}
                        label={'Música'}
                        onChange={(e) => changeInput(e)}
                    />
                    <Input
                        className={'form-control'}
                        name={'imagem_thumb'}
                        label={'Imagem thumb'}
                        value={data.imagem_thumb}
                        onChange={(e) => changeInput(e)}
                    />

                    <Input
                        className={'form-control'}
                        name={'mp3_url'}
                        label={'URL MP3'}
                        value={data.mp3_url}
                        onChange={(e) => changeInput(e)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        <i className={'fa fa-times'}></i> Fechar
                    </Button>
                    <Button variant="success" onClick={() => save()}>
                        <i className={'fa fa-save'}></i> Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )

}