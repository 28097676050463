import React, { useEffect, useState, } from 'react'
import {Button,  Table, Modal, Row, Col} from 'react-bootstrap';
import Pagination from '../../Components/Pagination/Pagination';

import Backend from "../../Api/Backend";

//scss
import './Index.scss'

import {Input} from "../../Components/Form";
import Message from "../../Components/Message/Message";

export default function Index(props) {

    const [list, setList] = useState([]);
    const [pagination, setPagination] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [data, setData] = useState({
        id_video_youtube: null,
        titulo:'',
        video: '',
    });

    useEffect(() => {
        getList();

    }, [props]);


    async function getList(){
        try{
            let params = new URLSearchParams(props.location.search);
            params.append('paginacao',true);
            params.append('itens',10);

            let list = await Backend.get('/videos-youtube?'+params);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setList(list.data.data.data);
            setPagination(list.data.data);
        }catch(e){
            Message.error(e)
        }
        
    }

    async function remove(id){
        if (window.confirm("Tem certeza que deseja excluir?")) {
            try{

                let list = await Backend.delete('/videos-youtube/'+id);

                if(list.data.status === "error"){
                    throw list.data.message;
                }

                getList();

                Message.success(list.data.message);
            }catch(e){
                Message.error(e)
            }
        }
    }

    async function save(){
        try{
            let returnSave = {};
            
            if(data.id_video_youtube){
                returnSave = await Backend.put('/videos-youtube/'+data.id_video_youtube, data);
            }else{
                returnSave = await Backend.post('/videos-youtube/', data);
            }

            if(returnSave.data.status === 'error'){
                throw returnSave.data.message;
            }

            setShowModal(false);
            getList();
            Message.success(returnSave.data.message);
        }catch(e){
            Message.error(e)
        }
    }

    async function newVideoYoutube(){
        setShowModal(true)
        setData({
            id_video_youtube: null,
            titulo:'',
            video: '',
        })
    }

    async function editVideoYoutube(id){
        try{

            let list = await Backend.get('/videos-youtube/'+id);

            if(list.data.status === "error"){
                throw list.data.message;
            }

            setData(list.data.data[0]);

            setShowModal(true)
        }catch(e){
            Message.error(e)
        }
    }

    async function changeInput(e)
    {
        let dataEdit = data
        dataEdit[e.target.name] = e.target.value
        setData({...dataEdit})
    }


    return(
        <section  className="content content-page">
            <Row>
                <Col>
                    <h2 className={'mt-2'}>Youtube</h2>
                </Col>
                <Col>
                    <Button onClick={() =>  newVideoYoutube()} className={'float-right'}><i className="fas fa-plus"></i> Adicionar</Button>
                </Col>
            </Row>
            <div className={'card mt-3'}>

                <Table striped bordered hover >
                    <thead>
                        <tr>
                            <th>video</th>
                            <th>Título</th>

                            <th><i className="fas fa-edit"></i> Editar</th>
                            <th><i className="fas fa-trash-alt"></i> Excluir</th>
                        </tr>
                    </thead>

                    <tbody>
                    { list.map((item ,key)=> (
                        <tr key={item.id_video_youtube}>
                            <td>
                                <iframe  src={item.video}  />
                            </td>
                            <td>{item.titulo}</td>
                            <td>
                                <Button variant={'success'} onClick={() => editVideoYoutube(item.id_video_youtube)}><i className="fas fa-edit"></i> Editar</Button>
                            </td>
                            <td>
                                <Button variant={'danger'} onClick={() => remove(item.id_video_youtube)}><i className="fas fa-trash-alt"></i> Excluir</Button>
                            </td>
                        </tr>
                    )) }
                    </tbody>
                </Table>
            </div>
            <Pagination  pagination={pagination} />
            
            <Modal show={showModal} onHide={() => setShowModal(false)} >
                <Modal.Header closeButton>
                    <Modal.Title>Criar/Editar Podcast</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Input
                        className={'form-control'}
                        name={'titulo'}
                        value={data.titulo}
                        label={'Título'}
                        onChange={(e) => changeInput(e)}
                    />

                    <Input
                        className={'form-control'}
                        name={'video'}
                        value={data.video}
                        label={'Video (URL)'}
                        onChange={(e) => changeInput(e)}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => setShowModal(false)}>
                        <i className={'fa fa-times'}></i> Fechar
                    </Button>
                    <Button variant="success" onClick={() => save()}>
                        <i className={'fa fa-save'}></i> Salvar
                    </Button>
                </Modal.Footer>
            </Modal>
        </section>
    )

}