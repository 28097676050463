import React, { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, useLocation, Redirect } from 'react-router-dom'

//public views
import Login from './Pages/Login/Login'

//private views

import HomeIndex from './Pages/Home/Index';
import PlaylistsIndex from './Pages/Playlists/Index';
import PlaylistsMusicas from './Pages/Playlists/Musicas';
import PodcastsIndex from './Pages/Podcasts/Index';
import PodcastsEpisodios from './Pages/Podcasts/Episodios';
import YoutubeIndex from './Pages/Youtube/Index';

//components
import LayoutPrivate from './Components/Layout/LayoutPrivate'

//export function default
export default function Routes() {
    useEffect(() => { 
    }, [])

    function NoMatch() {

        let location = useLocation();

        return (
            <div id="page-no-banner">
                <section>
                    <div className="container">
                        <h1>Página não encontrada!</h1>
                        <h3><code>{location.pathname}</code></h3>
                    </div>
                </section>
            </div>
        )

    }

    function PrivateRoute({ children, ...rest })
    {
        return (

            <Route
                {...rest}
                render={({ location }) =>
                    window.localStorage.getItem('token') && window.localStorage.getItem('token') !== 'false' ? (
                        children
                    ) : (
                        <Redirect
                            to={{
                                pathname: "/",
                                state: { from: location }
                            }}
                        />
                    )
                }
            />

        )
    }

    // function __PrivateRoute({ children, ...rest }) {

    //     return (
    //         <Route {...rest} render={({ location }) =>
    //             !window.localStorage.getItem('token') ? (
    //                 <Redirect to={{pathname: "/login", state: { from: location }}} />
    //             ) : (
    //                 children
    //             )
    //         } />
    //     )

    // }

    return(

        <Router>

            <Switch>

                <Route path="/login" exact component={Login}/>
                <Route path="/" exact component={Login}/>

                <LayoutPrivate>
                    {/* HOME */}
                    <PrivateRoute exact path="/home/index">
                        <Route component={HomeIndex} />
                    </PrivateRoute>

                    {/* PLAYLISTS */}
                    <PrivateRoute exact path="/playlists/index">
                        <Route component={PlaylistsIndex} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/playlists/musicas/:id">
                        <Route component={PlaylistsMusicas} />
                    </PrivateRoute>

                    {/* PODCASTS */}
                    <PrivateRoute exact path="/podcasts/index">
                        <Route component={PodcastsIndex} />
                    </PrivateRoute>

                    <PrivateRoute exact path="/podcasts/episodios/:id">
                        <Route component={PodcastsEpisodios} />
                    </PrivateRoute>

                    {/* YOUTUBE */}
                    <PrivateRoute exact path="/youtube/index">
                        <Route component={YoutubeIndex} />
                    </PrivateRoute>


                </LayoutPrivate>

                <Route path="*">
                    <NoMatch />
                </Route>
            </Switch>
        </Router>
    )

}
